// @flow
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { Header, Icon, Message, Segment } from "semantic-ui-react";

import DashboardRoute from "modules/Dashboard/misc/Routes";
import { Redirect } from "Commons/Modules/routing";
import { PasswordForgottenRoute } from "Commons/Modules/auth/misc/Routes";
import { Dashboard } from "flavours/app-remind.me/LazySuspenseImports";
import { setLayout } from "redux/actions/layout";
import { Input, Button } from "shared/formElements";
import { validateConfirmPassword, validateNewPassword } from "Commons/Utilities/validators/myProfile";
import { passwordChangeRequested } from "redux/actions/PasswordActions";

import "./Password.scss";

type Props = {
  location: {
    search: string,
  },
};

const PasswordReset = ({ location }: Props) => {
  const { t } = useTranslation("auth");
  const [state, setState] = useState({ password: undefined, passwordConfirm: undefined });
  const [errors, setErrors] = useState({ password: undefined, passwordConfirm: undefined });
  const isLoggedIn = useSelector((user) => !!user.session?.access_token);
  const passwordState = useSelector((state) => state.password);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setLayout({
        header: { show: true, simple: false },
        sidenav: true,
        footer: true,
        breadcrumbs: true,
        breadcrumbsLayout: "wide",
        pageType: "wide",
        bgColor: "#f7f7f7",
      })
    );
  }, [dispatch]);

  const onChange = (e) => {
    const { name, value } = e.target;

    setErrors({
      password: validateNewPassword(name === "password" ? value : state.password),
      passwordConfirm: validateConfirmPassword(name === "password" ? value : state.password)(
        name === "passwordConfirm" ? value : state.passwordConfirm
      ),
    });

    setState({
      password: name === "password" ? value : state.password,
      passwordConfirm: name === "passwordConfirm" ? value : state.passwordConfirm,
    });
  };

  const isValid = () => {
    return state.password && state.passwordConfirm && !errors.passwordConfirm && !errors.password;
  };

  const handleSubmit = () => {
    if (state.password && isValid()) {
      const params = new URLSearchParams(location.search);
      const token: string = params.get("token") || "invalid-token";
      dispatch(passwordChangeRequested(state.password, token));
    }
  };

  const errorMessages = {
    "Invalid reset token.": (
      <Trans i18nKey={"auth:passwordReset.wrongLinkError"}>
        Text <a href={PasswordForgottenRoute}>Link</a> Moretext
      </Trans>
    ),
  };

  return isLoggedIn ? (
    <Redirect exact from={PasswordForgottenRoute} to={Dashboard} />
  ) : (
    <Segment raised className={"Password thinned-segment"}>
      <Header as={"h2"}>
        <Icon name="question circle outline" />
        {t("passwordReset.header")}
      </Header>
      <div className={"Password padded-div"}>{t("passwordReset.resetSubheader")}</div>
      <Input
        fullWidth
        className="Password padded-div"
        name="password"
        type="password"
        placeholder={t("passwordReset.newPassword")}
        error={errors.password}
        errorMessage={errors.password}
        onChange={onChange}
      />
      <Input
        fullWidth
        className="Password padded-div"
        name="passwordConfirm"
        type="password"
        placeholder={t("passwordReset.repeatPassword")}
        error={errors.passwordConfirm}
        errorMessage={errors.passwordConfirm}
        onChange={onChange}
      />
      {!passwordState.success && !passwordState.error && (
        <Button fullWidth type="submit" disabled={!isValid()} onClick={handleSubmit}>
          {t("passwordReset.changePassword")}
        </Button>
      )}
      {passwordState.error && (
        <Message className={"Password success-message"} negative content={errorMessages[passwordState.error]} />
      )}
      {passwordState.success && (
        <>
          <Message className={"Password success-message"} positive content={t("passwordReset.passwordChanged")} />
          <a href={DashboardRoute}>
            <Button fullWidth>{t("common:toDashboard")}</Button>
          </a>
        </>
      )}
    </Segment>
  );
};

export default PasswordReset;
