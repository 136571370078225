// @flow
import React from "react";
import { withRouter, matchPath } from "react-router-dom";
import { Breadcrumb } from "semantic-ui-react";

import type { Location } from "history";
import i18next from "../../i18n";
import { BlogCategoriesBreadCrumbRoutes, BlogCategorieRoutes, RatgeberRoute } from "modules/BlogPages/misc/Routes";
import RemindersRoute from "modules/Reminder/Reminders/Routes";
import DashboardRoute from "modules/Dashboard/misc/Routes";
import { documentSubRoute } from "modules/ChangeService/ChangeServices/submodules/Documents/misc/Routes";
import { baseRoute as topDealsBaseRoute, topDealsDetailRoute } from "modules/TopDeals/misc/Routes";
import { bonusRoute as rewardsBaseRoute } from "modules/Rewards/misc/Routes";
import {
  ChangeServiceMainPageRoute,
  ElectricityRoute,
  GasRoute,
  baseRoute as ChangeServicesMainPageRoute,
} from "modules/ChangeService/ChangeServices/Routes";
import MyProfileRoute from "modules/MyProfile/misc/Routes";
import { MyShortOffersBaseRoute } from "modules/MyShortOffers/misc/Routes";
import {
  FragenBaseRoute,
  FragenDetailRoute,
  UnternehmenJobsBaseRoute,
  UnternehmenJobsDetailRoute,
  UnternehmenBaseRoute,
  UnternehmenDetailRoute,
  VertragBaseRoute,
  VertragsDetailRoute,
  InternalBaseRoute,
  InternalDetailRoute,
  ContactRoute,
} from "modules/ContentPages/misc/ContentPageRoutes";
import { detailSubRoute as DetailSubRoute } from "modules/ChangeService/ChangeServices/submodules/Detail/misc/Routes.js";
import { historySubRoute as HistorySubRoute } from "modules/ChangeService/ChangeServices/submodules/History/misc/Routes.js";

import { EditSubRoute } from "modules/ChangeService/ChangeServices/submodules/Edit/misc/Routes";
import { tariffSubRoute } from "modules/ChangeService/ChangeServices/submodules/Tariff/misc/Routes";

export const routes = {
  "/": i18next.t("dashboard:homeBreadCrumbName"),
  [DashboardRoute]: i18next.t("dashboard:dashboardBreadCrumbName"),
  [topDealsBaseRoute]: i18next.t("topDeals:breadCrumbName"),
  [topDealsDetailRoute]: (params) => params.deal_slug,
  [RemindersRoute()]: i18next.t("reminder:reminders.breadCrumbName"),
  [rewardsBaseRoute]: i18next.t("myBonuses:title.main"),
  // Routes for log out
  [ChangeServiceMainPageRoute]: i18next.t("changeService:breadCrumbName"),
  [ChangeServicesMainPageRoute]: i18next.t("changeService:breadCrumbName"),
  [ElectricityRoute]: [`${i18next.t("common:electricity")}-${i18next.t("changeService:name")}`],
  [GasRoute]: [`${i18next.t("common:gas")}-${i18next.t("changeService:name")}`],
  // Routes for logged in
  [DashboardRoute + ChangeServiceMainPageRoute]: i18next.t("changeService:breadCrumbName"),
  [DashboardRoute + ElectricityRoute]: (_, pathname) => [
    `${i18next.t("common:electricity")}`,
    pathname.includes("bearbeiten") ? pathname.replace("bearbeiten", "detail") : undefined,
  ],
  [DashboardRoute + ElectricityRoute + DetailSubRoute]: undefined,
  [DashboardRoute + ElectricityRoute + EditSubRoute]: [
    `${i18next.t("changeService:changeServices.navigation.personalDetails")}`,
  ],
  [DashboardRoute + ElectricityRoute + HistorySubRoute]: [
    `${i18next.t("changeService:changeServices.navigation.history")}`,
  ],
  [DashboardRoute + ElectricityRoute + tariffSubRoute]: [
    `${i18next.t("changeService:changeServices.navigation.details")}`,
  ],
  [DashboardRoute + ElectricityRoute + documentSubRoute]: i18next.t("documents:breadCrumbName"),
  [DashboardRoute + GasRoute + documentSubRoute]: i18next.t("documents:breadCrumbName"),
  [DashboardRoute + GasRoute]: (_, pathname) => [
    `${i18next.t("common:gas")}`,
    pathname.includes("bearbeiten") ? pathname.replace("bearbeiten", "detail") : undefined,
  ],
  [DashboardRoute + GasRoute + DetailSubRoute]: undefined,
  [DashboardRoute + GasRoute + HistorySubRoute]: [`${i18next.t("changeService:changeServices.navigation.history")}`],
  [DashboardRoute + GasRoute + EditSubRoute]: [
    `${i18next.t("changeService:changeServices.navigation.personalDetails")}`,
  ],
  [DashboardRoute + GasRoute + tariffSubRoute]: [`${i18next.t("changeService:changeServices.navigation.details")}`],

  [MyProfileRoute]: i18next.t("myProfile:myProfileBreadCrumbName"),
  [FragenBaseRoute]: i18next.t(`contentPages:breadCrumbName.${FragenBaseRoute}`),
  [FragenDetailRoute]: (params) => params.content_slug,
  [ContactRoute]: i18next.t("sideMenu:contact"),
  [UnternehmenJobsBaseRoute]: i18next.t(`contentPages:breadCrumbName.${UnternehmenJobsBaseRoute}`),
  [UnternehmenJobsDetailRoute]: (params) => params.content_slug,
  [UnternehmenBaseRoute]: i18next.t(`contentPages:breadCrumbName.${UnternehmenBaseRoute}`),
  [UnternehmenDetailRoute]: (params) => params.content_slug,
  [VertragBaseRoute]: i18next.t(`contentPages:breadCrumbName.${VertragBaseRoute}`),
  [VertragsDetailRoute]: (params) => params.content_slug,
  [InternalBaseRoute]: i18next.t(`contentPages:breadCrumbName.${InternalBaseRoute}`),
  [InternalDetailRoute]: (params) => params.content_slug,
  [MyShortOffersBaseRoute]: i18next.t("myShortOffers:myShortOffers:breadCrumbName"),
  ...BlogCategoriesBreadCrumbRoutes,
};

const isFunction = (value): boolean %checks => typeof value === "function";

const getPathTokens = (pathname) => {
  const paths = ["/"];

  if (pathname === "/") return paths;

  pathname.split("/").reduce((prev, curr) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  });

  return paths;
};

function getRouteMatch(routes, path) {
  return Object.keys(routes)
    .map((key) => {
      const params = matchPath(path, {
        path: key,
        exact: true,
        strict: false,
      });
      return params === null
        ? { didMatch: false, params, key }
        : {
            didMatch: params.isExact !== false,
            params: params.params,
            key,
          };
    })
    .filter((item) => item.didMatch)[0];
}

type BreadcrumbType = {
  name: string,
  path: string,
};

export function getBreadcrumbs({
  routes,
  location,
}: {
  routes: Dict,
  location: { pathname: string },
}): BreadcrumbType[] {
  const pathTokens = getPathTokens(location.pathname);

  return pathTokens
    .map((path) => {
      const routeMatch = getRouteMatch(routes, path);
      const routeValue = routeMatch && routes[routeMatch.key];
      const textAndDestination = isFunction(routeValue) ? routeValue(routeMatch.params, location.pathname) : routeValue;
      //textAndDestination value is gotten from the const routes
      // textAndDestination = [breadCrumbText, onClickRedirectTo],
      // if [breadCrumbText] -> onClickRedirectTo = path
      // if [breadCrumbText, undefined] -> breadCrumbText is not clickable

      if (textAndDestination) {
        if (Array.isArray(textAndDestination)) {
          let string = textAndDestination[0].split("-").join(" ");
          return { name: string.charAt(0).toUpperCase() + string.slice(1), path: textAndDestination[1] };
        } else {
          let string = textAndDestination.split("-").join(" ").replace("vollmacht", "Vollmacht");
          return { name: string.charAt(0).toUpperCase() + string.slice(1), path };
        }
      }

      return {
        name: "",
        path: "",
      };
    })
    .filter((breadcrumb) => breadcrumb.name !== "");
}

type Props = {
  location: Location,
};

export function injectRatgeberCrumbs(breadcrumbs: BreadcrumbType[]) {
  if (
    breadcrumbs &&
    BlogCategorieRoutes &&
    breadcrumbs.length >= 2 &&
    BlogCategorieRoutes.indexOf(breadcrumbs[1].path) !== -1
  ) {
    const [first, ...rest] = breadcrumbs;
    return [first, { name: "Ratgeber", path: RatgeberRoute }, ...rest];
  }
  return breadcrumbs;
}

function Breadcrumbs({ location }: Props) {
  let breadcrumbs: BreadcrumbType[] = injectRatgeberCrumbs(getBreadcrumbs({ routes, location }));
  if (breadcrumbs.length > 1) {
    return (
      <>
        <Breadcrumb vocab="http://schema.org/" typeof="BreadcrumbList">
          {breadcrumbs.map((breadcrumb, i) => {
            if (breadcrumb && breadcrumb.name) {
              return (
                <React.Fragment key={i}>
                  <Breadcrumb.Section property="itemListElement" typeof="ListItem" key={breadcrumb.path}>
                    {i < breadcrumbs.length && breadcrumb.path && breadcrumb.path !== location.pathname ? (
                      <a
                        className="basic-layout-breadcrumb-link"
                        property="item"
                        typeof="WebPage"
                        href={breadcrumb.path}
                        data-cy={"breadcrumb-link"}
                      >
                        <span property="name">{breadcrumb.name}</span>
                      </a>
                    ) : (
                      <span property="item" typeof="WebPage" resource={breadcrumb.path}>
                        <span property="name">{breadcrumb.name}</span>
                      </span>
                    )}

                    <meta property="position" content={i} />
                  </Breadcrumb.Section>

                  {i < breadcrumbs.length - 1 ? <Breadcrumb.Divider icon="right angle" /> : ""}
                </React.Fragment>
              );
            }
            return null;
          })}
        </Breadcrumb>
      </>
    );
  } else {
    return null;
  }
}

export default withRouter(Breadcrumbs);
