// @flow
import * as React from "react";
import { withTranslation, Trans } from "react-i18next";
import { Container, Header, Icon, Button } from "semantic-ui-react";
import { connect, type match } from "react-redux";

import DashboardRoute from "../../../../Dashboard/misc/Routes";
import { fetchTopDeal } from "../../../../TopDeals/redux/actions";
import { getSpecificTopDealUrl } from "../../../../TopDeals/misc/Routes";
import { TemporaryStorage } from "../../../../../Commons/Utilities/index";

import "./SuccessPage.scss";
import { LoginRoute } from "../../../../../Commons/Modules/auth/misc/Routes";
import type { TopDealT } from "../../../../TopDeals/misc/Types";

type Props = {
  t: typeof TFunction,
  match: match,
  fetchTopDeal: Function,
  location: { state: { topDealId: string, fromNewsletter: boolean } },
  topDeal: TopDealT,
};

const SubscribeMessage = (t, topDeal, fromNewsletter) => (
  <div data-cy="newsletter-subscribe-success-message">
    <Header as="h2" icon>
      <Icon name="checkmark" color="green" size="massive" />
      {t("successPage.subscribe.title")}
      <Header.Subheader>
        {fromNewsletter ? (
          t("successPage.subscribe.newsletterOnly")
        ) : (
          <Trans t={t} i18nKey="successPage.subscribe.subTitle">
            <p>Text</p>Text
          </Trans>
        )}
      </Header.Subheader>
      {topDeal ? (
        <a
          onClick={() => {
            TemporaryStorage.setAfterLoginRedirectURL(getSpecificTopDealUrl(topDeal.id, topDeal.title));
          }}
          href={LoginRoute}
          className={"SuccessPage button"}
        >
          <Button primary color="green" data-cy="topDeal-cta-button">
            {t("successPage.subscribe.topDealButton")}
          </Button>
        </a>
      ) : (
        !fromNewsletter && (
          <a href={DashboardRoute} className={"SuccessPage button"}>
            <Button primary color="green">
              {t("auth:login")}
            </Button>
          </a>
        )
      )}
    </Header>
  </div>
);

const UnsubscribeMessage = (t) => (
  <Header as="h2" icon>
    <Icon name="checkmark" color="green" size="massive" />
    {t("successPage.unsubscribe.title")}
    <Header.Subheader>{t("successPage.unsubscribe.subTitle")}</Header.Subheader>
  </Header>
);

const MESSAGES = {
  subscribe: SubscribeMessage,
  unsubscribe: UnsubscribeMessage,
};

let SuccessPage = class SuccessPage extends React.Component<Props> {
  componentDidMount() {
    const {
      fetchTopDeal,
      location: {
        state: { topDealId },
      },
    } = this.props;

    if (topDealId) {
      fetchTopDeal(topDealId);
    }
  }

  render() {
    const {
      t,
      match: { params },
      location: {
        state: { fromNewsletter },
      },
      topDeal,
    } = this.props;

    return <Container textAlign="center">{MESSAGES[params.action](t, topDeal, fromNewsletter)}</Container>;
  }
};

const mapStateToProps = ({ topDeals }, { location }) => {
  let topDeal = {};

  if (location.state) {
    return {
      topDeal: topDeals.topDeals.find((topDeal) => topDeal.id === location.state.topDealId),
    };
  }

  return {
    topDeal,
  };
};

const mapDispatchToProps = { fetchTopDeal };

SuccessPage = withTranslation("newsletter")(SuccessPage);
SuccessPage = connect(mapStateToProps, mapDispatchToProps)(SuccessPage);

export default SuccessPage;
