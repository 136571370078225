// @flow
import * as React from "react";
import { Button, Divider, Label } from "semantic-ui-react";

import { getCosts } from "../../../misc/Utils";
import { ChangeServicesRoute } from "../../../../ChangeService/ChangeServices";
import { IsUserAuthenticated } from "../../../../../Commons/Modules";
import { CategoryComponent, CategoryTypes } from "../../../../../Commons/Modules/category";
import { Loading } from "../../../../../Commons/Components";
import { navigateTo } from "../../../../../Commons/Modules/routing";
import { isPresent } from "../../../../../Commons/Utilities/index";
import { OffersRoute } from "../../../../Offers";
import Offers from "../../../../Offers/state/Offers";
import { getCheapestOffer } from "../../../../Offers/misc/Utils";
import { Criterias } from "../../../../Offers/misc/Types";
import i18n from "../../../../../i18n";

import "./Summary.scss";

import type { CategorySlugT } from "../../../../../Commons/Modules/category";
import type { ReminderT } from "../../../misc/Types";

const centeredStyle = { textAlign: "center" };
const smallerTileBasis = "26%";
const biggerTileBasis = "37%";

type Props = {
  reminder: ReminderT,
};

export function Summary({ reminder }: Props) {
  return (
    <CategoryComponent id={reminder.categoryId}>
      {({ category }) => {
        const showChangeService =
          isPresent(category) &&
          (category.categoryType === CategoryTypes.ELECTRICITY || category.categoryType === CategoryTypes.GAS);

        const showOffer =
          isPresent(category) &&
          (category.categoryType === CategoryTypes.ELECTRICITY ||
            category.categoryType === CategoryTypes.GAS ||
            category.categoryType === CategoryTypes.CELLPHONE);

        return (
          <>
            <Divider section />
            <div className="Summary item">
              <Summary.Costs style={{ ...centeredStyle, flexBasis: biggerTileBasis }} reminder={reminder} />

              {showOffer ? (
                <>
                  <Summary.Divider />
                  <Summary.Offer
                    style={{ ...centeredStyle, flexBasis: showChangeService ? smallerTileBasis : biggerTileBasis }}
                    reminder={reminder}
                  />
                </>
              ) : null}

              {showChangeService ? (
                <>
                  <Summary.Divider />
                  <Summary.ChangeServiceLink
                    style={{ ...centeredStyle, flexBasis: biggerTileBasis }}
                    categorySlug={category && category.slug}
                  />
                </>
              ) : null}
            </div>
            <Divider section />
          </>
        );
      }}
    </CategoryComponent>
  );
}

Summary.Costs = function SummaryCosts({ reminder, ...props }: Props) {
  const costs = getCosts(reminder);

  return (
    <div {...props}>
      <div style={{ fontWeight: "bold" }}>{i18n.t("reminder:reminder.summary.yourCalculatedCosts")}</div>
      <div>{i18n.t("reminder:reminder.summary.yourCalculatedCostsAre")}</div>
      <div data-cy="yourCalculatedCosts" style={{ marginTop: "1em" }}>
        <Label>{costs ? `${costs} €` : "-"}</Label>
      </div>
    </div>
  );
};

Summary.Offer = function SummaryOffer({ reminder, ...props }: Props) {
  return (
    <div {...props}>
      <IsUserAuthenticated>
        {({ userId }: Dict) => {
          return (
            <CategoryComponent id={reminder.categoryId}>
              {({ category, isFetching: isCategoryFetching }) => {
                return (
                  <Offers reminder={reminder} userId={userId}>
                    {({ offers, isFetching: isOffersFetching, fetchingError }) => {
                      if (isPresent(fetchingError)) {
                        return i18n.t("reminder:reminder.summary.offerError");
                      }

                      if (isCategoryFetching || isOffersFetching) {
                        return <Loading />;
                      }

                      if (Array.isArray(offers)) {
                        const cheapestOffer = getCheapestOffer(offers);
                        const criteria =
                          isPresent(category) &&
                          (category.categoryType === CategoryTypes.ELECTRICITY ||
                            category.categoryType === CategoryTypes.GAS ||
                            category.categoryType === CategoryTypes.CELLPHONE)
                            ? category.slug
                            : Criterias.requirements;

                        return (
                          <>
                            <div style={{ fontWeight: "bold" }}>{i18n.t("reminder:reminder.summary.manualChange")}</div>
                            <div>{i18n.t("reminder:reminder.summary.offersStartingAt")}</div>
                            <div style={{ marginTop: "1em" }}>
                              {isPresent(cheapestOffer) ? (
                                <a
                                  href={OffersRoute({
                                    criteria,
                                    reminderId: reminder.id,
                                  })}
                                >
                                  <Label className="Summary summary-offer-label">
                                    <span data-cy="bestFittingOffer">
                                      {cheapestOffer.contractDetails.runningCosts +
                                        " € " +
                                        (cheapestOffer.contractDetails.period === 12 ? "j." : "mtl.")}
                                    </span>
                                    <span style={{ margin: "0 .5em" }}>|</span>
                                    <span>{i18n.t("common:view")}</span>
                                  </Label>
                                </a>
                              ) : (
                                <Label className="summary-offer-label">-</Label>
                              )}
                            </div>
                          </>
                        );
                      }
                      return null;
                    }}
                  </Offers>
                );
              }}
            </CategoryComponent>
          );
        }}
      </IsUserAuthenticated>
    </div>
  );
};

type ChangeServiceLinkProps = {
  categorySlug: CategorySlugT,
};

Summary.ChangeServiceLink = function SummaryChangeServiceLink({ categorySlug, ...props }: ChangeServiceLinkProps) {
  return (
    <div {...props}>
      <div style={{ fontWeight: "bold" }}>{i18n.t("reminder:reminder.summary.changeAutomatically")}</div>
      <Button
        secondary
        style={{ marginTop: "1em" }}
        onClick={() => {
          navigateTo(ChangeServicesRoute({ categorySlug }));
        }}
      >
        {i18n.t("reminder:reminder.summary.activateChangeService")}
      </Button>
    </div>
  );
};

Summary.Divider = function SummaryDivider() {
  return <div style={{ ...Summary.Divider.Style, alignSelf: "stretch" }} />;
};

Summary.Divider.Style = {
  borderRight: "solid 1px rgba(34, 36, 38, 0.15)",
  margin: "0 1rem",
};

export default Summary;
