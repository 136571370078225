// @flow
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "@apollo/client";
import { Loader, Dimmer } from "semantic-ui-react";
import { useLocation } from "react-router-dom";

import { Redirect } from "Commons/Modules/routing";
import { setLayout } from "redux/actions/layout";
import { Seo } from "Commons/Components/Seo";

import ContentPages from "modules/ContentPages/components/ContentPages";

import { getHomepageContent } from "./misc/queries";
import HomePageRow from "./components/HomePageRow";

type Props = {
  slug?: string,
};

const Homepage = ({ slug = "new-homepage-showcase" }: Props) => {
  const { loading, error, data } = useQuery(getHomepageContent(slug));

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(
      setLayout({
        header: { show: true, simple: false },
        sidenav: true,
        footer: true,
        breadcrumbs: false,
        pageType: "fullScreen",
        bgColor: "#ffffff",
      })
    );
  }, [dispatch, location]);

  if (error) {
    if (slug !== "new-homepage-showcase") return <ContentPages />;
    console.error(error);
    return <Redirect to="/" />;
  }

  if (loading) {
    return (
      <Dimmer active inverted>
        <Loader inverted content="Loading" data-testid="loader" />
      </Dimmer>
    );
  }

  if (!data?.homepageBy) {
    if (slug !== "new-homepage-showcase") return <ContentPages />;
    console.error("The homepage slug is missing in DB");
    return <Redirect to="/" />;
  }

  const {
    homepageBy: {
      seo: { metaDesc, title, opengraphTitle, opengraphDescription, opengraphImage },
      homepageBuilder: { rows },
    },
  } = data;

  const seoTitle = title ? title : opengraphTitle ? opengraphTitle : undefined;
  const seoDescription = metaDesc ? metaDesc : opengraphDescription ? opengraphDescription : undefined;
  const seoImage = opengraphImage?.sourceUrl;

  return (
    <>
      <Seo title={seoTitle} description={seoDescription} imageUrl={seoImage} />

      {rows.map((components, index) => (
        <HomePageRow key={"row_" + index} components={components} position={index + 1} />
      ))}
    </>
  );
};

export default Homepage;
