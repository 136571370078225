// @flow
import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import { Loader, Dimmer } from "semantic-ui-react";
import i18n from "i18next";

import FormSharedContext from "modules/SharedFormModules/misc/context";
import { read, readAndReplace } from "modules/SharedFormModules/misc/helpers/utilities";
import { Button } from "shared/formElements";
import { changeServiceFormInteraction, virtualPageview } from "Commons/Utilities/GoogleTagManager";
import { scrollToPosition } from "Commons/Utilities/scrollTo";
import { csFormPageStormUrl, csFormPageGasUrl } from "modules/ChangeService/FormPage/misc/Routes";
import { getMissingDataRoute } from "modules/ChangeService/ChangeServices/Routes";
import { getSingleChangeService } from "modules/ChangeService/misc/Api";
import DashboardRoute from "modules/Dashboard/misc/Routes";
import Spinner from "Commons/Components/Loading/Spinner/Spinner";

import { ReactComponent as IconStrom } from "Commons/Assets/iconsV2/strom.svg";
import { ReactComponent as IconGas } from "Commons/Assets/iconsV2/gas.svg";
import { ReactComponent as ErrorIcon } from "Commons/Assets/icons/error_outline.svg";

const Confirmation = () => {
  const {
    activeCategory,
    category: { categoryType },
    inheritedStyles,
    configurationData: {
      translations: { confirmation, glossary },
    },
  } = useContext(FormSharedContext);
  const [waitForMissingData, setWaitForMissingData] = useState<boolean>(false);

  const {
    button,
    confirmationStep,
    withPaddingBottom,
    confirmationHeader,
    confirmationCtaButton,
    confirmationAnotherCS,
    confirmationMissingFields,
    confirmationAnotherCSButton,
  } = inheritedStyles;

  // Set the page url of the existing page (keep params and hash)
  const pageUrl = new URL(window.location.href);

  useEffect(() => {
    // Trigger a virtual page view when CS created successfully
    pageUrl.searchParams.set("csf_step", "16");
    virtualPageview(pageUrl.href, "CSF Step 16_CONFIRMATION");
  }, []);

  const history = useHistory();
  const createdCS = useSelector(({ changeServices }) => changeServices.newlyCreatedChangeService);
  const isUpdating = useSelector(({ changeServices }) => changeServices.isUpdating);
  const isCreating = useSelector(({ changeServices }) => changeServices.isCreating);

  const missingCustomerNumber = !createdCS.customerNumber && !createdCS.startOfDeliveryDate;
  const missingCounterNumber = !createdCS.counterNumber && !createdCS.futureCounterNumber;

  const wait = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const redirectToMissingData = async () => {
    setWaitForMissingData(true);
    for (let i = 0; i < 10; i++) {
      await wait(2000);
      if (createdCS.id) {
        try {
          const data = await getSingleChangeService(createdCS.userId, createdCS.id);
          const cs = data[createdCS.categoryId][0];
          if (cs.hasMissingData && cs.missingDataDetails) {
            scrollToPosition(0, 0);
            setWaitForMissingData(false);
            history.push(getMissingDataRoute(activeCategory.slug, createdCS.id));
            break;
          }
        } catch {
          console.error("Please check your email inbox and activate your account.");
          history.push("/login");
          break;
        }
      }
    }
    // If for some reason we are not redirected yes, stop waiting and redirect
    scrollToPosition(0, 0);
    setWaitForMissingData(false);
    history.push(getMissingDataRoute(activeCategory.slug, createdCS.id));
  };

  return isUpdating || isCreating ? (
    <div style={{ height: "420px" }}>
      <Dimmer active inverted>
        <Loader inverted content={i18n.t("common:loading")} data-testid="loader" />
      </Dimmer>
    </div>
  ) : missingCustomerNumber || missingCounterNumber ? (
    <div className={confirmationStep}>
      <div className={confirmationHeader}>
        <b>{read(confirmation.titleWithMissingData, "confirmation.titleWithMissingData")}</b>
      </div>

      <p>{read(confirmation.message.missingData, "confirmation.message.missingData")}</p>

      <ul className={confirmationMissingFields}>
        {missingCustomerNumber && (
          <li>
            <ErrorIcon />
            <span>{read(confirmation.fields.customerNumber, "confirmation.fields.customerNumber")}</span>
          </li>
        )}
        {missingCounterNumber && (
          <li>
            <ErrorIcon />
            <span>{read(confirmation.fields.counterNumber, "confirmation.fields.counterNumber")}</span>
          </li>
        )}
      </ul>

      <Button
        className={classnames(button, confirmationCtaButton)}
        onClick={() => redirectToMissingData()}
        disabled={waitForMissingData}
      >
        <span>{read(confirmation.buttons.completeData, "confirmation.buttons.completeData")}</span>
        {waitForMissingData && <Spinner />}
      </Button>
    </div>
  ) : (
    <>
      <div className={classnames(confirmationStep, withPaddingBottom)}>
        <div className={classnames(confirmationHeader, "theme-color-primary")}>
          {read(confirmation.title, "confirmation.title")}
        </div>

        <p>{read(confirmation.message.part1, "confirmation.message.part1")}</p>
        <p>{read(confirmation.message.part2, "confirmation.message.part2")}</p>

        <a href={DashboardRoute} onClick={() => scrollToPosition(0, 0)}>
          <Button className={classnames(button, confirmationCtaButton)}>
            {read(confirmation.buttons.toDashboard, "confirmation.buttons.toDashboard")}
          </Button>
        </a>
      </div>
      <div className={classnames(confirmationAnotherCS, "theme-bgcolor-primary")}>
        {categoryType === "GAS" ? <IconStrom /> : <IconGas />}
        <span>
          {readAndReplace(
            confirmation.otherCS,
            {
              replace: (domNode) => {
                if (domNode.attribs && domNode.attribs.id === "replace") {
                  return <span>{categoryType && glossary[categoryType.toLowerCase()].transReverse}</span>;
                }
              },
            },
            "confirmation.otherCS"
          )}
        </span>
        <Button
          customColor
          className={classnames(button, confirmationAnotherCSButton, "theme-color-primary")}
          onClick={async () => {
            changeServiceFormInteraction("ANOTHER_PRODUCT_YES");
            history.push(categoryType.toLowerCase() === "gas" ? csFormPageStormUrl : csFormPageGasUrl);
            scrollToPosition(0, 0);
          }}
        >
          {readAndReplace(confirmation.buttons.yes, "confirmation.buttons.yes")}
        </Button>
      </div>
    </>
  );
};

export default Confirmation;
