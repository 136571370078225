//@flow

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import merge from "lodash.merge";

import { getUser, getCategories } from "redux/sagas/selectors";
import { changeServiceFormInteraction, virtualPageview } from "Commons/Utilities/GoogleTagManager";
import { getTrackingData } from "Commons/Utilities/Tracking/TrackingHandler";
import { createChangeService, resetState } from "modules/ChangeService/redux/actions";
import { fetchCategoryBySlug } from "redux/actions/category";
import { normalizeData } from "modules/ChangeServiceForm/misc/utilities";
import { deviceMetadata } from "Commons/Utilities/getDeviceMetadata";
import { scrollToFormTop } from "modules/SharedFormModules/misc/helpers/utilities";
import type { SharedStateT } from "modules/SharedFormModules/misc/types";
import { SharedStateProvider } from "modules/SharedFormModules/misc/context";
import styleVersions from "./components/versions/styles";
import componentVersions from "./components/versions/components";
import dataVersions from "./components/versions/datas";
import LocalStorage from "Commons/Utilities/storage/LocalStorage";

type Props = {
  componentName: string,
  styleName: string,
  dataName: string | Object,
  customization?: {
    data: string,
  },
};

const ChangeServiceForm = ({ componentName, styleName, dataName, customization = {} }: Props) => {
  const user = useSelector(getUser);
  const categories = useSelector(getCategories);
  const changeServices = useSelector((state) => state.changeServices);
  const dispatch = useDispatch();
  const [step, setFormStep] = useState(1);
  const [categoryType, setCategoryType] = useState("");
  const [signature, setSignature] = useState({});
  const [calligrapherSignature, setCalligrapherSignature] = useState({});
  const [showLoginOrRegister, setShowLoginOrRegister] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalSteps, setTotalSteps] = useState(0);
  const [stepName, setStepName] = useState("");
  const [shouldCreateCS, setShouldCreateCS] = useState(false);
  const [CSValues, SetCSValues] = useState(null);
  const [cancelOldContract, setCancelOldContract] = useState(null);
  const [csCreated, setCsCreated] = useState(false);
  const [creationError, setCreationError] = useState(false);
  // Workaround to save form value when create of a cs is failing so the values can be restored when redirecting to the overview page.
  const [formStateBuffer, setFormStateBuffer] = useState(null);

  /* Extracted versions */
  const ComponentVersion: function = componentVersions[componentName];
  const inheritedStyles = styleVersions[styleName];
  const configurationBase = dataVersions[dataName];
  const commonConfiguration = dataVersions.common;

  /* Error handling */
  if (!ComponentVersion || !inheritedStyles || !configurationBase) {
    throw new Error(
      "Unable to load the correct versions of the component. Make sure that the string names of the props match the indexed version names and that they are correctly registered in the indexes of each /version folders (see /components or /datas or /styles)"
    );
  }

  /* Customization */
  const customizationData = customization?.data ? JSON.parse(customization?.data) : {};
  const configurationData = merge({}, commonConfiguration, configurationBase, customizationData);

  /* Category initialization */
  const { initialValues } = configurationData;

  useEffect(() => {
    // Get both category details
    dispatch(fetchCategoryBySlug("strom"));
    dispatch(fetchCategoryBySlug("gas"));
  }, []);

  useEffect(() => {
    setCategoryType(initialValues.initialCategory);
  }, [initialValues.initialCategory]);

  const activeCategory = categories?.find((category) => category?.categoryType === categoryType);
  /* Steps */
  const stepsCount = configurationData.stepsDependencies.length;
  let loggedUser = user?.user;

  useEffect(() => {
    const totalSteps = !loggedUser ? stepsCount + 2 : stepsCount + 1;
    setTotalSteps(totalSteps);
    return () => {
      dispatch(resetState());
    };
  }, []);

  // Set the page url of the existing page (keep params and hash)
  const pageUrl = new URL(window.location.href);

  const redirectToOverview = () => {
    setFormStep(14);
    setConfirmed(false);
    setCreationError(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    if (user?.user && shouldCreateCS && CSValues) {
      setShouldCreateCS(false);
      setCreationError(false);

      loggedUser = user.user;
      const trackingInfo = getTrackingData();
      const queryParams = LocalStorage.get("queryParams");

      let signatureAsBase64 =
        CSValues.signatureType === "MANUAL"
          ? signature?.signature?.split(",")[1]
          : calligrapherSignature?.split(",")[1];

      dispatch(
        createChangeService(
          {
            ...normalizeData(CSValues),
            ...trackingInfo,
            ...(queryParams
              ? { queryParams: typeof queryParams === "string" ? queryParams : JSON.stringify(queryParams) }
              : undefined),
            categoryId: activeCategory.id,
            latestDeviceMetadata: deviceMetadata,
            signInUrl: window.location.pathname,
          },
          loggedUser,
          signatureAsBase64,
          {
            onSuccess: () => setCsCreated(true),
            onError: () => {
              // Trigger a virtual page view when CS creation failed
              pageUrl.searchParams.set("csf_step", "14");
              virtualPageview(pageUrl.href, "CSF Step 16_FAILED");
              redirectToOverview();
            },
          }
        )
      );
    }
  }, [dispatch, user, shouldCreateCS, CSValues]);

  const proceedToConfirmation = () => {
    setConfirmed(true);
    changeServiceFormInteraction("CS_DONE");
    setShowLoginOrRegister(false);
    scrollToFormTop(16, "csFormContainer");
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    if (CSValues && !changeServices.creationError && csCreated) {
      setCsCreated(false);
      SetCSValues(null);
      proceedToConfirmation();
    }
  }, [changeServices.creationError, CSValues, csCreated]);

  /* Final submit on confirmation step */
  const onFormFinalSubmit = async (values) => {
    setLoading(true);
    setShouldCreateCS(true);
    SetCSValues(values);
  };

  const sharedState: SharedStateT = {
    inheritedStyles,
    configurationData,
    category: { categoryType, setCategoryType },
    loginOrRegisterStep: { showLoginOrRegister, setShowLoginOrRegister },
    steps: { step, setFormStep, totalSteps, setTotalSteps, stepName, setStepName, stepsCount },
    onFormFinalSubmit,
    confirmation: { confirmed, setConfirmed },
    user,
    loading,
    activeCategory,
    initialValues,
    signature: { signature, setSignature },
    calligrapherSignature: { calligrapherSignature, setCalligrapherSignature },
    cancelOldContract: { cancelOldContract, setCancelOldContract },
    creationError,
    formStateBuffer: { state: formStateBuffer, update: setFormStateBuffer },
  };

  return (
    <div className={inheritedStyles.container} id="csFormContainer">
      <SharedStateProvider value={sharedState}>
        <ComponentVersion />
      </SharedStateProvider>
    </div>
  );
};

export default ChangeServiceForm;
